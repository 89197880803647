<template>
  <a :href="`mailto:${email}`" class="custom-btn email-btn">
    <BootstrapIcon
            icon="envelope"
            size="1x"
          />
  </a>
</template>

<script>
export default {
  props: {
    email: { type: String, default: '' },
  },
}
</script>

<style lang="scss" scoped></style>
